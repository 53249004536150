.login{ 
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100%;
    background-color: #121214;
        
}

.login-logo img{
    width: 250px;
    height: 250px;
}

.login-right  {
    background-color: #29292E;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
    padding: 40px;
    width: 400px;
    height: 500px;
}

.login-right h1 {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 40px;
    margin-bottom: 20px;
    align-content: center;
    text-align: center;
}

.login-right h4 {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    align-content: center;
    text-align: center;
    font-size: 13.3px;
    font-weight: 300;
    margin-top: 10px;
    color: aqua;

}

.login-loginInputEmail {
    display: flex;
    align-items: center;
    color: gray;
    background-color: #1A1A1D ;
    border-radius: 5px;
    padding: 7px;
    width: 98%;
    height: 10%;
    
}

.login-loginInputEmail svg {
    margin-left: 10px;
    font-size: 25px;
    background-color: transparent;
}

.login-loginInputEmail input {
    background: transparent;
    width: 98%;
    outline-width: 0;
    color:gray;
    border:none;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
}

.login-loginInputPassword {
    display: flex;
    align-items: center;
    color: gray;
    background-color: #1A1A1D ;
    border-radius: 5px;
    padding: 7px;
    width: 98%;
    height: 10%;
    
}

.login-loginInputPassword svg {
    margin-left: 10px;
    font-size: 25px;
    background-color: transparent;
}

.login-loginInputPassword input {
    width: 98%;
    background: transparent;
    width: 98%;
    outline-width: 0;
    color:gray;
    border:none;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    
}

.login-right button {
    width: 103%;
    /*background-color: #121214;*/
    background-color:rgb(166, 168, 7);
    color: #EFF2F5;
    font-weight: 800;
    font-family:'Arial';
    height: 50px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 5px;
    outline-width: 0;
}

.login-right button:hover {
    background-color:rgb(65, 158, 158);
    color: #EFF2F5;
    cursor: pointer;
}